<template>
  <div class="relative w-6 h-6">
    <svg class="w-6 h-6">
      <circle
        cx="12"
        cy="12"
        r="8.5"
        fill="none"
        stroke-width="3.5"
        :stroke="type === 'secondary' ? '#e0f0e8' : '#339c65'"
      />
      <linearGradient
        v-if="type === 'secondary'"
        id="gradient"
        gradientTransform="rotate(90)"
      >
        <stop offset="0%" stop-color="white" />
        <stop offset="100%" stop-color="#00833E" />
      </linearGradient>
      <circle
        cx="12"
        cy="12"
        r="8.5"
        class="origin-center animate-spin"
        fill="none"
        stroke-linecap="round"
        stroke-width="3.5"
        :stroke="type === 'secondary' ? 'url(#gradient)' : 'white'"
        :stroke-dasharray="CIRCLE_LENGTH"
        :stroke-dashoffset="getProgress"
      />
    </svg>
  </div>
</template>

<script setup lang="ts">
type Props = {
  type?: 'primary' | 'secondary';
};

withDefaults(defineProps<Props>(), {
  type: 'primary',
});

const CIRCLE_LENGTH = 53.38;

const getProgress = computed(() => {
  return 0.75 * CIRCLE_LENGTH;
});
</script>
