<template>
  <button
    class="button"
    :class="buttonClasses"
    :disabled="disabled || loading"
    @click="onClickHandler"
  >
    <slot>
      {{ label }}
    </slot>
    <div v-if="icon || loading" class="icon">
      <BaseLoader v-if="loading" :type="loaderType" />
      <BaseSvgNew v-else-if="icon" :name="icon" />
    </div>
  </button>
</template>

<script setup lang="ts">
type Props = {
  label: string;
  type?: 'primary' | 'secondary' | 'tetriary' | 'step';
  icon?: string;
  disabled?: boolean;
  loading?: boolean;
  small?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  type: 'primary',
});

type Emits = {
  (e: 'click'): void;
};

const emit = defineEmits<Emits>();

const loaderType = computed(() => {
  return props.type === 'secondary' ? 'secondary' : 'primary';
});

const onClickHandler = () => {
  if (props.disabled || props.loading) return;
  emit('click');
};

const buttonClasses = computed(() => [
  props.type,
  { small: props.small },
  { loading: props.loading },
  { disabled: props.disabled || props.loading },
]);
</script>

<style scoped lang="scss">
.button {
  @apply flex items-center justify-center;
  @apply py-2 px-5;
  @apply text-base font-bold;
  @apply border-2 border-solid rounded-[80px];
  @apply cursor-pointer;
  @apply border-green-basic;
  > .icon {
    @apply shrink-0 ml-2 w-6 h-6;
  }
  &:not(.disabled):active {
    @apply opacity-80;
  }
  &.primary {
    @apply bg-green-basic text-white transition-opacity;
  }
  &.secondary {
    @apply bg-transparent text-green-basic transition-all;
    &:not(:disabled):hover {
      @apply bg-green-basic text-white;
    }
  }
  &.tetriary {
    @apply border-none text-green-basic transition-opacity;
  }
  &.step {
    @apply bg-transparent border-2 border-white  text-white transition-all duration-300;
    &:hover {
      @apply bg-white text-green-basic;
    }
  }
  &.disabled {
    @apply cursor-auto;
  }
  &.small {
    @apply text-sm leading-[18px];
    > .icon {
      @apply w-4 h-4;
    }
  }
  &.step {
    @apply transition-all border-white text-white hover:bg-white hover:text-green-basic;
  }
}
</style>
